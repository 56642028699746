@import "./variables.scss";

@mixin base-button {
  cursor: pointer;
  text-decoration: none;
  overflow: hidden;

  /** Ripple Effect -- needs a stacking context */
  transform: translate3d(0, 0, 0);
  /** Hover Effect */
  transition: all 150ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
}

@mixin large-button {
  @include base-button;
  padding: 13px 30px;
  border-radius: $roundedCorner;
  text-decoration: none;
  font-size: clamp(1.2rem, 1.6vw, 1.6rem);
}

@mixin small-button {
  @include base-button;
  padding: 11px 30px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8.4px; //20% of 42px as per our style guide.
  height: 42px;
  line-height: 21px;
  white-space: nowrap;
}

/**
* Hover Effects
*/
.regularButton,
.outlineButton,
.tabButton,
.underlineButton {
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1.025);
  }
}

.underlineButton:hover {
  text-decoration: underline !important;
}

.outline {
  @include small-button;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: var(--primary-color);
  color: var(--primary-color);
  font-weight: 400;
  line-height: 18px; //need to hack line-height because of hacks to margins to make everything line up with solid buttons.
}

.primary {
  @include small-button;
  background-color: var(--primary-color);
  color: white;
  border: 2px solid var(--primary-color);
}
