$blue: #56caff;
$green: #b4e86a;
$lgreen: #f0fae1;
$li_grey: #e5e5e5;
$v_li_grey: rgba(245, 245, 245, 1);

$grey: #fafafa;
$off_white: rgb(219, 217, 217);
$blackish: #1e1e1e;
$gray_opacity: rgba(78, 78, 78, 0.5);
$darkButtonGradient: linear-gradient(267.3deg, #1c1d21 0%, #4a4d57 100%);

$transOverlayGradient: linear-gradient(
  242.87deg,
  rgba(28, 28, 28, 0.6) 25.9%,
  rgba(43, 43, 43, 0.6) 100%
);
$darkGradient: linear-gradient(218.81deg, #000000 12.27%, #2b2b2b 78.87%);
$blueGradient2: linear-gradient(
  265.66deg,
  transparentize(#30afef, 0.2) 0%,
  transparentize(#bce7fd, 0.2) 140.23%,
  transparentize(#56caff, 0.2) 140.23%
);

$blueGradient: linear-gradient(265.98deg, #30afef -28.93%, #bce7fd 100%);
$greenGradient: linear-gradient(266.94deg, #79ce00 -43.12%, #cced9d 100%);
$greyGradient: linear-gradient(266.52deg, #ffffff 0.53%, #e5e5e5 100%);
$largeRoundedCorner: 60px;
$roundedCorner: 15px;
$smallRoundedCorner: 8px;

$fineBoxShadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
$fineBoxShadowLight: 0px 1px 8px -8px rgba($off_white, 0.8);

$boxshadow: 0 4px 4px -4px rgba(30, 30, 30, 0.2);

/* For small screens we are using max-width, for larger than desktop we use min-width so we offset by 1 so that there is not a pixel width that gets neither style.*/
$screenMobileSmall: 600px;
$screenMobile: 900px;
$screenDesktop: 901px;
$screenDesktopLarge: 1400px;

$maxSplashWidth: 980px;

$headerHeight: 60px;
$footerHeight: 334px;
$topPadding: calc(160px + $headerHeight);
$bottomPadding: 30px;

$sectionPaddingSide: 114px;
$sectionPaddingTop: 90px;
$sectionPadding: $sectionPaddingTop $sectionPaddingSide;
