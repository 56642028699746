.clampedText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  max-height: 75px;
}

.unclampedText {
}

.showMoreButton,
.showLessButton {
  margin-top: 8px;
  cursor: pointer;
}

.showMoreButton {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
}

.showLessButton {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
}
