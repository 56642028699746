.playIcon::before {
  content: '';
  margin-right: 15px;
  width: 20px;
  box-sizing: border-box;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-radius: 2px;
  border-color: transparent transparent transparent #ffffff;
  display: inline-block;
  line-height: 25px;
}
