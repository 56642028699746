.collapseFlex {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $screenMobile) {
      flex-direction: row;
      flex-flow: row-reverse;
    }
  }

  .desktopHidden {
    @media only screen and (min-width: $screenDesktop) {
      display: none;
    }
  }

  .mobileHidden {
    @media only screen and (max-width: $screenMobile) {
      display: none;
    }
  }

  .mobileBlock {
    @media only screen and (max-width: $screenMobile) {
      flex-basis: 100%;
    }
  }