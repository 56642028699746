@import "./variables.scss";
@import './forms.scss';
@import "./buttons.scss";
@import "./layout.scss";
@import "./responsive.scss";
@import "./mixins.scss";
@import "./keyframes.scss";
@import "./icons.scss";

.iconGrid {
  display: grid;
  width: 80%;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5%;
  grid-row-gap: 3vh;
  place-items: start center;

  .iconGridItem {
    grid-area: 1fr;
    display: flex;
    flex-direction: column;
    .lottie {
      inset: 15px;
      height: 150px;
      width: 150px;
      margin: auto;
    }
  }

  @media screen and (max-width: $screenMobile) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: $screenMobileSmall) {
    grid-template-columns: 1fr;
  }
}

.callout {
  position: relative;
  text-align: center;
  margin: 30px 10%;
  border: 1px solid $li_grey;
  border-radius: 10px;
  box-shadow: 10px -10px 20px rgba($li_grey, 0.5);

  a {
    text-decoration: none;
    color: $blackish;
  }

  img {
    object-fit: contain !important;
  }
}

.collapsingBackground {
  background-color: $off_white;
  background-size: min(45%, 650px);
  background-position: left 50vw center;
  background-repeat: no-repeat;
  background-origin: padding-box;

  @media only screen and (max-width: $screenMobile) {
    padding-top: 300px;
    background-size: 400px;
    background-position: top center;
    text-align: center;

    h1,
    h2,
    h3,
    p {
      width: 100%;
      max-width: 100% !important;
      text-align: center;
    }
  }
}

@mixin volteraList {
  position: relative;
  list-style: none; /* Remove default bullets */

  li {
    margin-bottom: 46px;
  }
  li::before {
    position: absolute;
    content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $blue; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1.5rem; /* Also needed for space (tweak if needed) */
    font-size: 2.5rem;
    margin-left: -1em; /* Also needed for space (tweak if needed) */
    line-height: 22px;
  }
}

.novaListHL {
  @include volteraList;
  li::before {
    color: $blue; /* Change the color */
  }
}
.voneListHL {
  @include volteraList;
  li::before {
    color: $green; /* Change the color */
  }
}

.voneList {
  list-style: none;
  li {
    position: relative;
  }
  li:before {
    content: "✓";
    position: absolute;
    color: $green;
    font-size: 170%;
    left: -25px;
    top: -12px;
  }
}

.black {
  background: $blackish;
  color: white;
}
.white {
  background: white;
}

.videoBreak {
  width: 100vw;
  padding: 0 !important;
  margin: auto;
  object-fit: cover;
  overflow: hidden;
  video {
    width: 100%;
  }

  @media only screen and (max-width: $screenMobile) {
    margin: -30px auto;

    object-fit: cover;
    video {
      min-height: 400px;
      object-fit: cover;
    }
  }
}

.carouselWrapper {
  overflow: hidden;
  .carouselContent {
    padding: 10px 60px;
    min-height: 225px;
    text-align: left;
    h4 {
      white-space: nowrap;
    }
  }
  .carouselImage {
    position: relative;
    width: 100%;
    aspect-ratio: 2/1;
    img,
    video {
      position: relative !important;
      width: 100vw;
      //top: 0;
      left: 0;
      right: 0;
      bottom: -10px;
      object-fit: cover;
      box-shadow: 1px 1px 10px black;
    }
  }
}
