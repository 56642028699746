.wrapper {
  position: relative;
  width: 100%;
  height: 18px;
  border-radius: 9px;
  background-color: lightgray;
  overflow: hidden;

  .fill {
    background-color: var(--primary-color);
    height: 18px;
    transition: width 0.4s ease-in-out;
    position: relative;

    .text {
      color: white;
      font-family: "Roboto";
      float: right;
      font-size: 14px;
      line-height: 18px;
      margin-right:5px;
    }
  }
}
