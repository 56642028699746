@import './variables.scss';

@mixin section-defaults {
  max-width: 100%;
  padding-left: $sectionPaddingSide;
  padding-right: $sectionPaddingSide;
  overflow-x: auto;
  position: relative;

  @media only screen and (min-width: $screenDesktopLarge) {
    padding-left: 8%;
    padding-right: 8%;
  }

  @media only screen and (max-width: $screenMobile) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and (max-width: $screenMobileSmall) {
    padding-left: 5px;
    padding-right: 5px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.main {
  overflow: hidden;
  padding-top: $headerHeight;
}

.sectionPadding {
  @include section-defaults;
  padding: $sectionPadding;
}

.lightSection {
  @include section-defaults;
  width: 100vw;
}

.greySection {
  @include section-defaults;
  background: $grey;
  width: 100vw;
}

.navBarPadding {
  padding-top: $topPadding !important;
}

.dark {
  background: $darkGradient;
  color: white;
}

.darkSection {
  @include section-defaults;
  background: $darkGradient;
  color: white;
  width: 100vw;
}

.dark {
  background: $darkGradient;
  color: white;
}

.fullWidth {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.subSection {
  margin: 15px 0;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.textRight {
  text-align: right;
}

.flexWrap {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  @media only screen and (max-width: $screenMobile) {
    flex-direction: column;
    width: 100%;
    padding: 0 5px;
  }
}
.flexRowLeft {
  @extend .flexRow;
  justify-content: left;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.popoverCallToAction {
  visibility: hidden;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 102;
  width: 100vw;
  padding: 15px;
  transition: all 0.25s ease-in-out;
  top: 0;
  background-color: white;
  box-shadow: $boxshadow;

  a,
  button {
    width: auto;
    margin: 0 10px;

    display: inline;
  }

  @media only screen and (max-width: $screenMobile) {
    //need to pad right to
    background: white;
    padding: 10px 0;
    justify-content: center;
    gap: 8px;

    a,
    button {
      white-space: nowrap;
    }
  }
}

.popoverCallToAction:hover {
  background: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.spinnerContainer {
  width: 100%;
  min-height: 500px;
  position: relative;
  display: flex;
  inset: 0;
  transition: height 0.5s ease-in-out;
}

.spinnerContainer.fadeOut {
  height: 0;
}

.overflowHidden {
  overflow: hidden;
}

.noPadding {
  padding: 0 !important;
}

.relativePosition {
  position: relative;
}

.clampWidth {
  max-width: 1440px;
  margin: auto;
  width: 100%;
}

.hidden {
  display: none;
}
