// .contactContainer {
//   .formContainer {
//     form {
//       div {
//         width: 100%;
//       }
//       fieldset {
//         display: flex;
//         max-width: 100%;
//         width: 100%;
//         margin-bottom: 20px;
//         grid-gap: 5%;
//       }

//       input,
//       select {
//         height: 42px;
//         border-radius: $smallRoundedCorner;
//         background-color: $grey;
//         display: inline-block;
//       }

//       textarea {
//         height: 150px;
//         border-radius: $smallRoundedCorner;
//         background-color: $grey;
//         display: inline-block;
//       }
//       input:not(input[type="submit"]),
//       select,
//       textarea {
//         border: 1px solid $li_grey;
//         width: 100% !important;
//         margin-bottom: 10px;
//         margin-top: 10px;
//         padding: 0px 15px;
//         font-size: 16px;
//         color: $blackish;
//       }

//       legend {
//         display: none;
//       }

//       input[type="button"],
//       input[type="submit"],
//       input[type="reset"] {
//         border: none;
//         background: $blackish;
//         color: $grey;
//         width: auto;
//         margin: 20px auto;
//         display: inline;
//         padding: 0px 30px;
//         font-size: 16px;
//         /** Hover Effect */
//         transition: all 150ms ease-in-out;
//         &:hover {
//           transform: scale(1.05);
//         }
//         &:active {
//           transform: scale(1.025);
//         }
//       }

//       div:has(input[type="button"], input[type="submit"], input[type="reset"]) {
//         text-align: center;
//       }

//       select option:disabled {
//         // opacity: 0.5;
//         //color: rgba($blackish, 0.5);
//       }

//       option:first-of-type {
//         color: #9e9e9e;
//       }

//       label::after {
//         color: red;
//         margin-left: 3px;
//       }

//       ul {
//         margin-top: 0;
//         text-align: left;
//       }
//       li {
//         font-size: 1rem;
//         color: red;
//         margin: -5px 0 0 0;
//         animation: vibrate 0.3s forwards;
//         &::marker {
//           content: "*";
//         }

//         label {
//           font-size: 1rem;
//           color: red;
//         }
//       }
//     }
//   }
// }

.formSection {
  animation: slideLeftIn 1s ease-in-out forwards;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

label {
  display: grid;
  
  position: relative;
  width: 100% !important;
  flex-direction: column;
  text-align: left;
  font-size: 1rem;
}

.checkboxWrapper {
  label {
    display: flex;
    flex-direction: row;
    gap: 15px;
    input {
      min-width: 25px;
    }
  }
}
.checkboxWrapper input[type="checkbox"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 25px;
  height: 25px;
  border-radius: 4px;
  margin-right: 5px;
  border: 2px solid var(--primary-color);
  outline: none;
  cursor: pointer;
}

.checkbox-wrapper input[type="checkbox"]:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:disabled + span {
  color: #c0c0c0;
}

.checkbox-wrapper input[type="checkbox"]:focus {
  box-shadow: 0 0 10px var(--primary-color);
}

input.checked {
  background-color: var(--primary-color);
  position: relative;
}

input.checked::before {
  content: "\2713";
  font-size: 20px;
  line-height: 25px;
  color: #fff;
  position: absolute;
  right: 2px;
  top: -1px;
}
