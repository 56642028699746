$delayFadeIn: fadeIn 350ms ease-in-out 1200ms forwards;
$fadeIn: fadeIn 400ms ease-in-out forwards;
$fadeOut: fadeOut 100ms ease-in-out forwards;

.fadeOut {
  animation: $fadeOut;
}

.fadeIn {
  animation: $fadeIn;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  99% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
    display: none;
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  1% {
    visibility: visible;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes slideFadeInOut {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  22% {
    opacity: 1;
    transform: translateX(0);
  }

  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

$delayedSlideFadeIn: slideFadeIn 300ms ease-out forwards;

@keyframes slideFadeIn {
  0% {
    opacity: 0;
    transform: translateX(100px);
    max-width: 100vw;
    overflow: hidden;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.collapsed {
  transition: max-height 100ms;
  max-height: 0;
  overflow: hidden;
  * {
    opacity: 0;
    transition: opacity 100ms;
  }
}

.expanded {
  display: block;
  transition: max-height 250ms ease-in-out;
  max-height: 600px;

  * {
    animation: $delayedSlideFadeIn;
  }
}
